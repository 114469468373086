/*
 * @Author: mulingyuer
 * @Date: 2021-07-06 15:54:06
 * @LastEditTime: 2022-01-04 17:47:29
 * @LastEditors: aleaner
 * @Description:管理证书列表
 * @FilePath: \buildd:\hujiang-project\saas-admin-vue\src\modules\cert-manage\api\manage.js
 * 怎么可能会有bug！！！
 */
import api from "@/base/utils/request";
import fetchFile from "@/base/utils/download";

//会内职务数据
export const positionLevel = data => {
  return api({
    url: "/admin/cyc/position/positionLevel",
    method: "post",
    data
  })
}


//管理列表
export const manageList = data => {
  return api({
    url: "/admin/cert/cert_manage/index",
    method: "post",
    data
  })
}


//收回证书
export const recoveryCert = data => {
  return api({
    url: "/admin/cert/cert_manage/recoveryCert",
    method: "post",
    data
  })
}

//颁发证书
export const certify = data => {
  return api({
    url: "/admin/cert/cert_manage/certify",
    method: "post",
    data
  })
}

//会员数据
export const userList = data => {
  return api({
    url: "/admin/cyc/organization/multiSelect",
    method: "post",
    data
  })
}

// 证书名称列表导出
export const documentCertManageList = data => {
  return fetchFile({
    url: "/admin/cert/CertManage/export",
    method: "post",
    data,
    download: true
  });
};