<!--
 * @Author: mulingyuer
 * @Date: 2021-07-06 15:31:56
 * @LastEditTime: 2022-01-04 17:48:57
 * @LastEditors: aleaner
 * @Description: 证书管理筛选
 * @FilePath: \buildd:\hujiang-project\saas-admin-vue\src\modules\cert-manage\components\manage\Filter.vue
 * 怎么可能会有bug！！！
-->
<template>
  <LayoutFilter :onFilter="onFilter" :onReset="onReset" :onExport="onExport">
    <el-form :model="form" :rules="rules" ref="ruleForm" label-width="70px" size="medium" class="filter-from">
      <div class="filter-item">
        <DatePeriodPicker label="颁发时间：" start-placeholder="开始日期" :start-time.sync="form.create_start_time"
          start-prop="create_start_time" end-placeholder="结束日期" :end-time.sync="form.create_end_time"
          end-prop="create_end_time" />
      </div>
      <div class="filter-item">
        <el-form-item label="会员名称：" prop="user_name">
          <el-input v-model="form.user_name" placeholder="请输入会员名称"></el-input>
        </el-form-item>
        <el-form-item label="会内职务：" prop="positionIds">
          <el-cascader v-model="form.positionIds" :options="positionArr"
            :props="{label:'name',value:'id',children:'position'}">
          </el-cascader>
        </el-form-item>
        <el-form-item label="证书名称：" prop="cert_name">
          <el-input v-model="form.cert_name" placeholder="请输入证书名称"></el-input>
        </el-form-item>
      </div>
      <div class="filter-item">
        <el-form-item label="奖项名称：" prop="prize_name">
          <el-input v-model="form.prize_name" placeholder="请输入奖项名称"></el-input>
        </el-form-item>
        <el-form-item label="证书编号：" prop="cert_number">
          <el-input v-model="form.cert_number" placeholder="请输入证书编号"></el-input>
        </el-form-item>
        <el-form-item label="证书类型：" prop="type">
          <el-select v-model="form.type" placeholder="请选择证书类型">
            <el-option v-for="op in typeArr" :key="op.id" :label="op.name" :value="op.id"></el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="filter-item">
        <el-form-item label="证书状态：" prop="type">
          <el-select v-model="form.status" placeholder="请选择证书状态">
            <el-option v-for="op in statusArray" :key="op.id" :label="op.name" :value="op.id"></el-option>
          </el-select>
        </el-form-item>
      </div>
    </el-form>
  </LayoutFilter>
</template>

<script>
import LayoutFilter from "@/base/layout/LayoutFilter";
import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";
import { positionLevel, documentCertManageList } from "../../api/manage";
import { typeArr, pushTypeArr, statusArray } from "../../data/default";
export default {
  props: {
    //筛选回调
    filterChange: {
      required: true,
      type: Function,
    },
    //导出要用的数据
    exportData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {
        create_start_time: -1, // 开始时间
        create_end_time: -1, // 结束时间
        user_name: "", //会员名称
        positionIds: [], //会内职务
        name: "", //证书名称
        prize_name: "", //证书奖项名称
        cert_number: "", //证书编号
        type: -1, //证书类型
        status: -1, //证书状态
      },
      rules: {},
      //会内职务选项数据
      positionArr: [],
      typeArr, //证书类型选项数据
      pushTypeArr, //推送渠道选项数据
      statusArray, //证书状态选项数据
    };
  },
  methods: {
    //筛选
    onFilter() {
      return this.filterChange(Object.assign({}, this.form));
    },
    //重置
    onReset() {
      this.$refs["ruleForm"].resetFields();
      this.onFilter();
    },
    //导出
    onExport() {
      documentCertManageList({ ...this.exportData, ...this.form }).catch(
        (err) => {}
      );
    },
    //获取会内职务数据
    getPositionLevel() {
      positionLevel({ is_list_tree: 1 })
        .then((res) => {
          const { data } = res;
          this.positionArr.push(...data);
        })
        .catch(() => {});
    },
  },
  created() {
    this.getPositionLevel();
  },
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
};
</script>

<style lang="scss" scoped>
</style>
