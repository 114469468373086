<!--
 * @Author: mulingyuer
 * @Date: 2021-07-06 17:44:48
 * @LastEditTime: 2021-08-09 11:43:03
 * @LastEditors: mulingyuer
 * @Description: 颁发弹窗
 * @FilePath: \saas-admin-vue\src\modules\cert-manage\components\manage\AwardDialog.vue
 * 怎么可能会有bug！！！
-->
<template>
  <el-dialog title="颁发证书" :visible.sync="show" width="500px" class="dialog-vertical receive-info-dialog"
    @open="onOpen" @closed="onClose">
    <div class="content" v-loading="loading">
      <el-form :model="form" :rules="rules" ref="ruleForm" label-width="75px" class="medium-form">
        <el-form-item label="证书名称：">
          <div class="form-text">{{certName|placeholder}}</div>
        </el-form-item>
        <el-form-item label="证书ID：" prop="cert_id">
          <div class="form-text">{{certId|placeholder}}</div>
        </el-form-item>
        <el-form-item label="选择会员：" prop="position_id">
          <el-cascader v-model="form.position_id" :options="userData"
            :props="{ multiple: true,children:'_children',label:'name',value:'id',emitPath:false }"
            collapse-tags filterable placeholder="请选择会员（可搜索会员名称）">
          </el-cascader>
        </el-form-item>
        <el-form-item label="推送渠道：">
          <el-checkbox v-model="form.system_message" :true-label="1" :false-label="0">系统消息</el-checkbox>
          <!-- <el-checkbox v-model="form.sms_notice" :true-label="1" :false-label="0">短信通知</el-checkbox> -->
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <el-button size="small" @click="show = false">取消</el-button>
      <el-button v-if="!loading" type="primary" size="small" :loading="saveLoading"
        @click="onConfirm('ruleForm')">
        确定
      </el-button>
    </template>
  </el-dialog>
</template>

<script>
import { userList, certify } from "../../api/manage";
export default {
  model: {
    prop: "open",
    event: "handleOpen",
  },
  props: {
    //是否打开
    open: {
      type: Boolean,
      required: true,
    },
    //证书id
    certId: {
      type: [Number, String],
      required: true,
    },
    //证书name
    certName: {
      type: String,
      required: true,
    },
    //完成的回调
    complete: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false, //加载中
      form: {
        cert_id: "", //证书id
        position_id: [], //用户id
        system_message: 1, //是否系统消息通知
        sms_notice: 0, //是否短信通知
      },
      rules: {
        position_id: [
          {
            type: "array",
            required: true,
            message: "请至少选择一个会员",
            trigger: "change",
          },
        ],
      },
      userData: [], //用户数据
      saveLoading: false, //保存中
    };
  },
  computed: {
    show: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("handleOpen", val);
      },
    },
  },
  methods: {
    //获取用户数据
    getUserList() {
      this.loading = true;
      const postData = { include_first: 1 };
      userList(postData)
        .then((res) => {
          const { data } = res;
          this.userData = data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    //打开
    onOpen() {
      this.$nextTick(() => {
        this.resetForm();
        this.getUserList();
      });
    },
    //关闭
    onClose() {
      this.resetForm();
    },
    //重置表单
    resetForm() {
      this.$refs["ruleForm"].resetFields();
    },
    //确认
    onConfirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveLoading = true;
          const postData = { ...this.form, ...{ cert_id: this.certId } };
          certify(postData)
            .then((res) => {
              const { msg } = res;
              this.$message.success(msg);
              this.saveLoading = false;
              this.show = false;
              this.complete(); //回调
            })
            .catch(() => {
              this.saveLoading = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
